import React from 'react';
import { Button, Modal,Input, Select ,Form,Row, Col,Upload} from 'antd';
import { FormWrapper } from '../../Style/style';
import { PlusOutlined } from '@ant-design/icons';

const ProductDownloadsModal = ({ productDownload,productDownloadModalActive,updateProductDownloadRow,lang,messages,toggleModal,beforeUpload}) => {
  const [form23] = Form.useForm();
  const uploadButton = (
    <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>{messages["button.upload"]}</div>
    </div>
  );

  return (
  <>
    <Modal
      title={messages["genel.edit"]}
      open={productDownloadModalActive}
      footer={
        <Button  size="large" type="primary"  onClick={() => toggleModal("productDownloadModalActive",null)}>
          {messages["button.close"]}
        </Button>
      }
      onCancel={() => toggleModal("productDownloadModalActive",null)}
      forceRender
      closable={false}
    >
      <FormWrapper style={{height:'auto'}}>
          <Form form={form23}  initialValues={productDownload} >
            <Row align="middle">
              <Col  xxl={6} xl={6} lg={6} md={6} xs={24}>
                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={(file)=>beforeUpload(file,"productDownload","base64")}
                >
                  {productDownload && productDownload.base64 ? <iframe src={productDownload.base64} alt="productDownload.name" width="120" /> : uploadButton}
                </Upload>
              </Col>
              <Col  xxl={18} xl={18} lg={18} md={18} xs={24}>
                <Row align="middle"  gutter={5}>
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["file.type"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name='type'>
                      <Input  onChange={(eData)=>updateProductDownloadRow("type",eData.target.value,0)}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle"  gutter={5}>
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">{messages["file.name"]}</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name='name'>
                      <Input readOnly/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle"  gutter={5}>
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">Açıklama</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name={['file_descirpiption', `${0}`]}>
                      <Input onChange={(eData)=>updateProductDownloadRow("file_descirpiption",eData.target.value,0)}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle"  gutter={5}>
                  <Col md={6} xs={24}>
                    <label htmlFor="input-text">Description</label>
                  </Col>
                  <Col md={18} xs={24}>
                    <Form.Item name={['file_descirpiption', `${1}`]}>
                      <Input onChange={(eData)=>updateProductDownloadRow("file_descirpiption",eData.target.value,1)}/>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Form>
      </FormWrapper>
    </Modal>
  </>
  );
}

export  {ProductDownloadsModal};
