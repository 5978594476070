import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useParams,NavLink ,useHistory} from 'react-router-dom';
import { Row, Col ,Button,message,notification,Popconfirm,Popover} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFilePdf,faList, faTrash, faSave,faRefresh} from '@fortawesome/free-solid-svg-icons';
import {useIntl} from "react-intl";
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../Style/styled';
import { MenuListWrapper} from '../Style/style';
import {getProductDetails,updateProduct,createProduct,deleteProduct} from '../../redux/productDetails/productDetailActions';

import {getMaterialsList} from '../../redux/materials/materialsActions';
import {getProductList} from '../../redux/productList/productListActions';
//import {getProductImageList} from '../../redux/productImageList/productImageListActions';
import {deleteProductStock,updateProductStockOperation,createProductStock} from '../../redux/productStockDetails/productStockDetailActions';
import {getCompanyList} from '../../redux/CompanyList/companyListActions';
import appActions from '../../redux/app/actions';
import {DetailMenu} from "../commonComponent/detailMenu";

import {ProductGenerals} from "./products/productProfile";
import {ElectricalInfo} from "./products/electricalInfo";
import { ElectricalInfoModal } from './products/electricalInfoModal';
import {OpticalInfo} from "./products/opticalInfo";
import { OpticalInfoModal } from './products/opticalInfoModal';
import {MechanicalInfo} from "./products/mechanicalInfo";
import { MechanicalInfoModal } from './products/mechanicalInfoModal';
import {OtherInfo} from "./products/otherInfo";
import {OtherInfoModal } from './products/otherInfoModal';
import {ProductPhotometryList } from './products/productPhotometryList';
import {ProductPhotometryModal} from "./products/productPhotometrModal";
import {TechnicalDrawingsList } from './products/technicalDrawingsList';
import {TechnicalDrawingModal} from "./products/tecnicalDrawingsModal";
import {OtherPhotoList } from './products/orherPhotosList';
import {ProductDownloads } from './products/productDownloads';
import {ProductDownloadsModal } from './products/productDownloadsModal';
import {OtherPhotoModal} from "./products/otherPhotosModal";
import {ProductionSteps } from './products/productionSteps';
import {ProductionStepModal } from './products/productionStepsModal';
import {ProductImages} from "./products/productImages";
import {ImageModal} from "./products/productImageModal";
import {StockOperations} from "./products/stockOperations";
import {StockRowModal} from "../commonComponent/StockRowModal";
import {ProductOperations} from "./products/productOperations";
import {ProModal} from "./products/productionModal";

import {CompanyListModal} from "../commonComponent/companyListModal";
import blank from '../../static/img/resyok.jpeg';

import moment from 'moment';


const ProductDetails = ( ) => {
  let history = useHistory();
  const {locale } = useSelector((state) => state.LanguageSwitcher.language);
  const {copyValues,copyForm,visibilityList,CurrencyList} = useSelector(state => state.App);
  
  const { productDetail, productDetailLoading,status} = useSelector(state => state.productDetail);
  const {materialList,materialsListLoading,materialListStatus} = useSelector((state) => state.materials);
  const {productListLoading,productList,productListStatus} = useSelector(state => state.productList);
  const {companyList,companyListLoading} = useSelector((state) => state.companyList);
   const { certificates } = useSelector(state => state.myCompany.myCompany);




  const [dataSource, setDataSource] = React.useState(productDetail);
  const [modelListOptions, setModelListOptions] = React.useState([]);
  const [stockRowData, setstockRowData] = React.useState();
  const [StockOpList, setStockOpList] = React.useState();
  const [companies, setCompanies] = React.useState(companyList);


  const {tcmb} = useSelector(state => state.tcmb);
  const USDSatis = tcmb && tcmb.Data[0].ForexSelling;
  const EURSatis = tcmb && tcmb.Data[1].ForexSelling;
  const [state, setState] = React.useState({
    electricalInfoModalActive:null,
    electricalInfoModalIndex:null,
    opticalInfoModalActive:null,
    opticalInfoModalIndex:null,
    mechanicalInfoModalActive:null,
    mechanicalInfoModalIndex:null,
    otherInfoModalActive:null,
    otherInfoModalIndex:null,
    productImageModalActive:null,
    productImageModalIndex:null,
    technicalDrawingModalActive:null,
    technicalDrawingModalIndex:null,
    productPhotometryModalActive:null,
    productPhotometryModalIndex:null,
    otherPhotoModalIndex:null,
    otherPhotoModalActive:null,
    productionStepModalIndex:null,
    productionStepModalActive:null,
    productDownloadModalIndex:null,
    productDownloadModalActive:null,
    proModalIndex:null,
    proModalActive:null,
    stockRowModalActive:null,
    
    productionCollapseActiveKey:1,
    companyListModalActive:null,
    tabID:"profil",
    collapsed:false
  });
  const { setCopyValues,setCopyForm } = appActions;
  const dispatch = useDispatch();
  const { productID } = useParams();
  const { messages } = useIntl();
  const lang = locale === 'tr' ? 0 :1
  const MenuList =[
    {tabID:"profil",MenuName:messages["product.profil"]},
    {tabID:"electrical",MenuName:messages["product.electricalinfo"]},
    {tabID:"optical",MenuName:messages["product.opticalinfo"]},
    {tabID:"mechanical",MenuName:messages["product.mechanicalinfo"]},
    {tabID:"other",MenuName:messages["product.otherinfo"]},
    {tabID:"images",MenuName:messages["product.images"]},
    {tabID:"drawings",MenuName:messages["product.drawings"]},
    {tabID:"photometry",MenuName:messages["product.photometry"]},
    {tabID:"otherphotos",MenuName:messages["product.otherphotos"]},
    {tabID:"productionsteps",MenuName:messages["product.productionsteps"]},
    {tabID:"operations",MenuName:messages["product.production"]},
    
    {tabID:"productDownloads",MenuName:messages["product.downloads"]},
    {tabID:"stock",MenuName:messages["product.stocks"]},
  ];
  const buttonList = [
    <div key={0}>
      <Popover content= {messages["button.refresh"]} placement="bottom">
        <Button shape="circle"  onClick={()=>refreshDataSource()} size='large' icon={<FontAwesomeIcon icon={faRefresh} color="#0328fc"/>} style={{marginRight:'10px'}}/>
      </Popover>
      {dataSource && dataSource._id && 
      <Popconfirm
        title={messages["alert.deletequest"]}
        okText={messages["genel.yes"]}
        cancelText={messages["genel.no"]}
        onConfirm={()=>onDeleteProduct(dataSource._id)}
      >
        <Button shape="circle" size='large' icon={<FontAwesomeIcon icon={faTrash} color="#ce1818"/>}  style={{marginRight:'10px'}}/>
      </Popconfirm>}
      <Popover content= {messages["button.save"]} placement="bottom">
        <Button shape="circle"  onClick={()=>onSaveProduct()} size='large' icon={<FontAwesomeIcon icon={faSave} />} style={{marginRight:'10px'}}/>
      </Popover>           

      <Popover content= {messages["product.datasheet"]} placement="bottom">
        <NavLink to={`/productdatasheet/${dataSource._id}`}>
          <Button shape="circle"   size='large' icon={<FontAwesomeIcon icon={faFilePdf} />} style={{marginRight:'10px'}}/>
        </NavLink>
      </Popover> 
      <Popover content= {messages["sidebar.products"]} placement="bottom">
        <NavLink key={4} to="/admin/products" className="project-edit">
          <Button shape="circle" type='primary' size='large' icon={<FontAwesomeIcon icon={faList}/>}/>
        </NavLink>
      </Popover>
    </div>
  ];
  const {collapsed,tabID} = state;
  const {summary,production,electricalInfo,opticalInfo,mechanicalInfo,otherInfo,technicalDrawings,productImages,productPhotometry,otherPhotos,productionSteps,productDownloads} = dataSource;
  //******************************************************************************************************************************************************************/
  const  mainImage = dataSource && dataSource.mainImage && dataSource.mainImage.length ? dataSource.mainImage[0].image : blank;
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if (productID !=="new" && dataSource._id !== productID) {
        dispatch((getProductDetails({id:productID})))
        .then(res=>{
          message.success(messages["alert.savesuccess"],  2 );
        });
    }
    if(productID === "new"){
      setDataSource({
        urun_sira:1,
        urun_adi:["","-"],
        urun_model:"",
        urun_kodu:"",
        urun_guc:"",
        urun_lumen:"",
        urun_durumu:true,
        urun_doviz:"$",
        urun_fiyat:0,
        urun_panel:"",
        urun_aku:"",
        urun_cct:"XXXX",
        product_dimensions:"",
        product_weight:"",
        urun_optik:"XX",
        production:[],
        urun_ozellikler:[]
      })
      //console.log("init")
    }

      const reqFilter  = {
        showPassiveProduct:false,
        selectedCategories:"Tümü"
      };
      dispatch(getProductList("list",reqFilter))
 

  },[]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    let Temp = [];
    [...new Map(productList && productList.map(x=> [x.urun_model,x])).values()].forEach(row => {
      Temp.push({
          value: row.urun_model,
          label: row.urun_model
        });
      });
    setModelListOptions(Temp)
  },[productList]);
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    
    if (productID !=="new") {
      //console.log("update")
      //let tempData = productDetail &&  productDetail.production &&  productDetail.production.length &&  productDetail.production.sort((a, b) => a.material_category.localeCompare(b.material_category));
      setDataSource(productDetail)
    }

      let operationList = [];
      let runingTotal = 0;
      productDetail.stockOperations && productDetail.stockOperations.length && productDetail.stockOperations.sort((a,b)=> new Date(a.operation_date)-new Date(b.operation_date)).forEach(mtr=>{
          runingTotal = runingTotal + mtr.sumQty
          operationList.push({
              runTotal: runingTotal,
              product_id : mtr.product_id,
              order_id : mtr.order_id,
              orderitem_id:mtr.orderitem_id,
              operation_center:mtr.operation_center,
              operation_date : mtr.operation_date,
              operation_type  : mtr.operation_type,
              description: mtr.description,
              qty:mtr.qty,
              unit:mtr.unit,
              price : mtr.price,
              doviz : mtr.doviz,
              sumQty:mtr.sumQty,
              sumPrice:mtr.sumPrice,
              _id:mtr._id
          });  
      })
    
      setStockOpList({
        operations:operationList
      }); 
    
  },[productDetail]);
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    if(productListStatus===401){
      notification.warning({
        message: 'Uyarı !',
        description:
          'Bu bölüm için yekiniz bulunmamaktadır. Sistem yöneticisine başvurunuz.',
      });
      history.push({pathname:"/admin"})
    }
  }, [productListStatus]);

  //******************************************************************************************************************************************************************/
  React.useEffect(() => {
    setCompanies(companyList)
  },[companyList]);
  //******************************************************************************************************************************************************************/
  const gotoPage = (page) => {
    if(page==='operations'){
      dispatch(getMaterialsList('list',null));
      dispatch(getProductList("list","model_category"));
    }else if(page==='stock'){
     // dispatch(getProductStockList(productID))
    }
    setState({
      ...state,
      tabID:page,
      collapsed:false
    })
  }
  //******************************************************************************************************************************************************************/
  function convertBase64  (file)  {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
   //******************************************************************************************************************************************************************/
  const beforeUpload = async (file,table,Field) => {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJPG && table != "productDownload") {
      message.error('You can only upload JPG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error('Image must smaller than 1024Kb!');
      return false;
    }
    const base64 = await convertBase64(file);

    let tempdata;
    if(table==="technicalDrawings"){
      tempdata = technicalDrawings;
      tempdata[state.technicalDrawingModalIndex][Field] =base64;
    }else if(table==="productImages"){
      tempdata = productImages;
      tempdata[state.productImageModalIndex][Field] =base64;
    }else if(table==="productPhotometry"){
      tempdata = productPhotometry;
      tempdata[state.productPhotometryModalIndex][Field] =base64;
    }else if(table==="otherPhotos"){
      tempdata = otherPhotos;
      tempdata[state.otherPhotoModalIndex][Field] =base64;
    }else if(table==="productDownload"){
      tempdata = productDownloads;
      tempdata[state.productDownloadModalIndex][Field] =base64;
      tempdata[state.productDownloadModalIndex].type =file.type;
      tempdata[state.productDownloadModalIndex].name =file.name;
    }
    setDataSource({
      ...dataSource,
      [table]: tempdata
    });
    message.success('Image uploaded successfully!');
    return true;
  }
  //******************************************************************************************************************************************************************/
  const onSaveProduct = () => {
    if(dataSource._id){
      dispatch(updateProduct({...dataSource}))
      .then(res=>{
        message.success(messages["alert.savesuccess"], 2 );
      })
    }else{
      dispatch(createProduct({...dataSource}, history))
      .then(res=>{
        message.success(messages["alert.savesuccess"], 2 );
      });
    }
  };
  //******************************************************************************************************************************************************************/
  const toggleModal = (name,val,data) =>{
    if(name==="companyListModalActive"){
      dispatch(getCompanyList(null));
      setState({
        ...state,
        [name]: val,
        companyListModalActive:true
      });
    }else if (name==="proModalIndex"){
      setState({
        ...state,
        [name]: val,
        proModalActive:true
      });
      !materialList.length && dispatch(getMaterialsList('list',null));
    }else if (name==="productImageModalActive"){
      setState({
        ...state,
        [name]: val,
      });
    }else if (name==="productImageModalIndex"){
      setState({
        ...state,
        [name]: val,
        productImageModalActive:true
      });
    }else if (name==="stockRowModalActive"){
      setState({
        ...state,
        [name]: val,
      });
      let tempData = data;
      if(val){tempData.operation_date = val &&  moment(data.operation_date)};
      setstockRowData(tempData);
    }else if (name==="electricalInfoModalActive"){
      setState({
        ...state,
        [name]: val
      });
    }else if (name==="electricalInfoModalIndex"){
      setState({
        ...state,
        [name]: val,
        electricalInfoModalActive:true
      });
    }else if (name==="opticalInfoModalActive"){
      setState({
        ...state,
        [name]: val
      });
    }else if (name==="opticalInfoModalIndex"){
      setState({
        ...state,
        [name]: val,
        opticalInfoModalActive:true
      });
    }else if (name==="mechanicalInfoModalActive"){
      setState({
        ...state,
        [name]: val
      });
    }else if (name==="mechanicalInfoModalIndex"){
      setState({
        ...state,
        [name]: val,
        mechanicalInfoModalActive:true
      });
    }else if (name==="otherInfoModalActive"){
      setState({
        ...state,
        [name]: val
      });
    }else if (name==="otherInfoModalIndex"){
      setState({
        ...state,
        [name]: val,
        otherInfoModalActive:true
      });
    }else if (name==="technicalDrawingModalActive"){
      setState({
        ...state,
        [name]: val
      });
    }else if (name==="technicalDrawingModalIndex"){
      setState({
        ...state,
        [name]: val,
        technicalDrawingModalActive:true
      });
    }else if (name==="productPhotometryModalActive"){
      setState({
        ...state,
        [name]: val
      });
    }else if (name==="productPhotometryModalIndex"){
      setState({
        ...state,
        [name]: val,
        productPhotometryModalActive:true
      });
    }else if (name==="otherPhotoModalActive"){
      setState({
        ...state,
        [name]: val
      });
    }else if (name==="otherPhotoModalIndex"){
      setState({
        ...state,
        [name]: val,
        otherPhotoModalActive:true
      });
    }else if (name==="productionStepModalIndex"){
      setState({
        ...state,
        [name]: val,
        productionStepModalActive:true
      })
    }else if (name==="productDownloadModalIndex"){
        setState({
          ...state,
          [name]: val,
          productDownloadModalActive:true
        });
    }else if (name==="productDownloadModalActive"){
      setState({
        ...state,
        [name]: val
      });
    }else if (name==="productionStepModalActive"){
      setState({
        ...state,
        [name]: val,
        productionStepModalActive:val
      });
    }
  };
  //******************************************************************************************************************************************************************/
  const toggleCollapsed = () => {
    setState({
      ...state,
      collapsed: !state.collapsed,
    });
  };
  //******************************************************************************************************************************************************************/
  const calculateProductionList = () => {
  
    let tempdata = dataSource.production;
    let summary = 0 ; 
    let materialListfilterResult = [];
    let materialListSum = 0;
    let price = 0;
    for(let i = 0; i < tempdata.length; i++){
      if (tempdata[i].op_name==="Yarı Mamül"){
        price = productList.filter(option => option._id === tempdata[i].material_group)[0].urun_fiyat
        
      }else{
        materialListfilterResult =  materialList && materialList.length   && materialList.filter(option => option.material_category === tempdata[i].material_category  && option.material_group === tempdata[i].material_group )        
        //console.log(materialListfilterResult)Burada USD Güncellemesi Yapmak Gerekiyor 
        materialListSum = materialListfilterResult.length>0 && materialListfilterResult.reduce((a, b) => a + b.material_price, 0);
        price = materialListfilterResult.length > 0 ? (materialListSum / materialListfilterResult.length) || 0 : tempdata[i].materialprice ;
      }
      let rowUSD = tempdata[i].materialcurrency==="₺" ? tempdata[i].materialqyt *( price/USDSatis) : tempdata[i].materialcurrency==="€" ? tempdata[i].materialqyt *( (price*EURSatis)/USDSatis) : tempdata[i].materialqyt*price ; 
      tempdata[i].rowTotal =  rowUSD
      tempdata[i].materialprice = price
      summary=summary+rowUSD
  
    }
    setDataSource({
      ...dataSource,
      production:tempdata,
      summary:parseFloat(summary)
    });
  }
  //******************************************************************************************************************************************************************/
  const dataCopy=(FormName)=>{
    dispatch(setCopyValues(dataSource[FormName]));
    dispatch(setCopyForm(FormName));
    message.info("Kopyalandı",3);
  }
  //******************************************************************************************************************************************************************/
  const dataPaste=(FormName)=>{
    if(copyValues &&  copyForm === FormName){
      setDataSource({
        ...dataSource,
        [FormName] : copyValues
      });
      message.info("Yapıştırıldı",3);
    }else{
        if (window.confirm("Kopyalanmış birimler uyuşmuyor veya kopyalama hatası")){}
    }
  }
  //******************************************************************************************************************************************************************/
  const onDeleteProduct = (id) => {
      if(dataSource._id){
        dispatch(deleteProduct(id))
        .then(res=>{
          message.success(messages["alert.savesuccess"], 2 );
          history.push({
            pathname : "/admin/products"
          })
        });
      };

  };
  //******************************************************************************************************************************************************************/
  const searchCompany = search => {
    let filteredList =[]; 
    filteredList =  companyList.filter(
        item => {return item.company_name.toLowerCase().includes(search.toLowerCase()) })
    if (search===""){
      setCompanies(companyList)
    }else{
      setCompanies(filteredList)
    }
  };
  //******************************************************************************************************************************************************************/
  const selectCompany = (company) => {
    let tempData= stockRowData;
    tempData.company_id=company._id;
    tempData.firma_adi=company.company_name;
    setstockRowData(tempData);
    setState({
      ...state,
      companyListModalActive:null
    });
  };
//******************************************************************************************************************************************************************/
  const onChangeProductModel = (e) =>{
    let tempdata = dataSource;
    tempdata.urun_model = e;
    setDataSource({
      ...dataSource,
      tempdata
    });

  }
  //******************************************************************************************************************************************************************/
  const updateData = (Field,eData) => {
    if(Field==="urun_adi"){
      let temp = dataSource.urun_adi;
      if (!temp){
        temp=["",""]
      }
      temp[lang]=eData;
      setDataSource({
        ...dataSource,
        urun_adi:temp
      });
    }else if(Field==="description"){
      let temp = dataSource.description;
      if (!temp){
        temp=["",""]
      }
      temp[lang]=eData;
      setDataSource({
        ...dataSource,
        description:temp
      });
    }else if(Field==="applicationArea"){
      let temp = dataSource.applicationArea;
      if (!temp){
        temp=["",""]
      }
      temp[lang]=eData;
      setDataSource({
        ...dataSource,
        applicationArea:temp
      });
    }else{
      //console.log(Field,eData)
      setDataSource({
        ...dataSource,
        [Field]:eData
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const refreshDataSource = () => {
    dispatch((getProductDetails({id:productID})))
    .then(res=>{
      message.success(messages["alert.savesuccess"],  2 );
    });
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const removeProRow = (index) => {
    let temp = dataSource.production;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        production:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const addNewProRow = () => {
    !materialList.length && dispatch(getMaterialsList('list',null));
    let temp = dataSource.production;
    temp.push({
      op_name:null,
      material_category:"",
      material_group:"",
      information: "",
      materialqyt:1,
      rowTotal:0
    })
    setState({
      ...state,
      proModalIndex: (temp.length)-1,
      proModalActive:true
    });
    setDataSource({
      ...dataSource,
      production:temp
    });

  };
  //******************************************************************************************************************************************************************/
  const updateProRow = (Field,eData) => {
    let tempdata = dataSource
      tempdata.production[state.proModalIndex][Field] = eData;
    if(Field==="materialqyt"){
      tempdata.production[state.proModalIndex].rowTotal=tempdata.production[state.proModalIndex].materialprice * eData
    }else if((Field==="materialprice")){
      tempdata.production[state.proModalIndex].rowTotal=tempdata.production[state.proModalIndex].materialqyt * eData
    }
    setDataSource({
      ...dataSource,
      tempdata
    });
  } 
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const addNewElectircalInfoRow = () => {
    let temp = electricalInfo;
    if (!temp){
      temp=[]
    }

    temp.push({
      no:5,
      label:["",""],
      value:["",""]

    })
    console.log("geldi")
    setState({
      ...state,
      electricalInfoModalActive: true,
      electricalInfoModalIndex:(temp.length)-1
    });
    setDataSource({
      ...dataSource,
      electricalInfo:temp
    });

  };
  //******************************************************************************************************************************************************************/
  const removeElectricalInfoRow = (index) => {
    let temp = electricalInfo;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        electricalInfo:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateElectricalInfoRow = (Field,eData,i) => {
    let tempdata = electricalInfo
    if(Field==="no"  || Field==="visibility" ){
      tempdata[state.electricalInfoModalIndex][Field] = Number(eData);
    }else{
      tempdata[state.electricalInfoModalIndex][Field][i] = eData;
    }
      
    setDataSource({
      ...dataSource,
      electricalInfo:tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const addNewOpticalInfoRow = () => {
    let temp = opticalInfo;
    if (!temp){
      temp=[]
    }

    temp.push({
      no:5,
      label:["",""],
      value:["",""]

    })
    setState({
      ...state,
      opticalInfoModalActive: true,
      opticalInfoModalIndex:(temp.length)-1
    });
    setDataSource({
      ...dataSource,
      opticalInfo:temp
    });

  };
  //******************************************************************************************************************************************************************/
  const removeOpticalInfoRow = (index) => {
    let temp = opticalInfo;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        opticalInfo:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateOpticalInfoRow = (Field,eData,i) => {
    let tempdata = opticalInfo
    if(Field==="no"  || Field==="visibility" ){
      tempdata[state.opticalInfoModalIndex][Field] = Number(eData);
    }else{
      tempdata[state.opticalInfoModalIndex][Field][i] = eData;
    }
      
    setDataSource({
      ...dataSource,
      opticalInfo:tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const addNewMechanicalInfoRow = () => {
    let temp = mechanicalInfo;
    if (!temp){
      temp=[]
    }
    temp.push({
      no:5,
      label:["",""],
      value:["",""]
    })
    setState({
      ...state,
      mechanicalInfoModalActive: true,
      mechanicalInfoModalIndex:(temp.length)-1
    });
    setDataSource({
      ...dataSource,
      mechanicalInfo:temp
    });

  };
  //******************************************************************************************************************************************************************/
  const removeMechanicalInfoRow = (index) => {
    let temp = mechanicalInfo;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        mechanicalInfo:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateMechanicalInfoRow = (Field,eData,i) => {
    let tempdata = mechanicalInfo
    if(Field==="no"  || Field==="visibility" ){
      tempdata[state.mechanicalInfoModalIndex][Field] = Number(eData);
    }else{
      tempdata[state.mechanicalInfoModalIndex][Field][i] = eData;
    }
      
    setDataSource({
      ...dataSource,
      mechanicalInfo:tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const addNewOtherInfoRow = () => {
    let temp = otherInfo;
    if (!temp){
      temp=[]
    }
    temp.push({
      no:5,
      label:["",""],
      value:["",""]
    })
    setState({
      ...state,
      otherInfoModalActive: true,
      otherInfoModalIndex:(temp.length)-1
    });
    setDataSource({
      ...dataSource,
      otherInfo:temp
    });

  };
  //******************************************************************************************************************************************************************/
  const removeOtherInfoRow = (index) => {
    let temp = otherInfo;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        otherInfo:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateOtherInfoRow = (Field,eData,i) => {
    let tempdata = otherInfo;
    if(Field==="no"  || Field==="visibility" ){
      tempdata[state.otherInfoModalIndex][Field] = Number(eData);
    }else{
      tempdata[state.otherInfoModalIndex][Field][i] = eData;
    }
      
    setDataSource({
      ...dataSource,
      otherInfo:tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const addTechnicalDrawingRow = () => {
    let temp = technicalDrawings;
    if (!temp){
      temp=[]
    }
    temp.push({
      no:5,
      image:"",
      description:["",""]
    })
    setState({
      ...state,
      technicalDrawingModalActive: true,
      technicalDrawingModalIndex:(temp.length)-1
    });
    setDataSource({
      ...dataSource,
      technicalDrawings:temp
    });

  };
  //******************************************************************************************************************************************************************/
  const removeTechnicalDrawingRow = (index) => {
    let temp = technicalDrawings;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        technicalDrawings:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateTechnicalDrawingRow = (Field,eData,i) => {
    let tempdata = technicalDrawings;
    if(Field==="no"){
      tempdata[state.technicalDrawingModalIndex][Field] = Number(eData);
    }else{
      tempdata[state.technicalDrawingModalIndex][Field][i] = eData;
    }
    setDataSource({
      ...dataSource,
      technicalDrawings:tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const addNewProductImageRow = () => {
    let temp = productImages;
    if (!temp){
      temp=[]
    }
    temp.push({
      no:5,
      image:"",
      imageType:["",""],
      description:["",""]
    })
    setState({
      ...state,
      productImageModalActive: true,
      productImageModalIndex:(temp.length)-1
    });
    setDataSource({
      ...dataSource,
      productImages:temp
    });
  };
  //******************************************************************************************************************************************************************/
  const removeProductImageRow = (index) => {
    let temp = productImages;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        productImages:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateProductImageRow = (Field,eData,i) => {
    let tempdata = productImages;
    if(Field==="no" || Field==="imageType"){
      tempdata[state.productImageModalIndex][Field] = eData;
    }else{
      tempdata[state.productImageModalIndex][Field][i] = eData;
    }
    setDataSource({
      ...dataSource,
      productImages:tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const onChangeStockRowData = (field,data) => {
    if(field==="qty"){
      setstockRowData({
        ...stockRowData,
        [field]:data,
        sumQty: stockRowData.operation_type ==="giris" ? data : 0-data,
        sumPrice:stockRowData.operation_type ==="giris" ? data*stockRowData.price : 0-(data*stockRowData.price)
      });
    }else if (field === "price" ){
      setstockRowData({
        ...stockRowData,
        [field]:data,
        sumPrice:stockRowData.operation_type ==="giris" ? data*stockRowData.qty : 0-(data*stockRowData.qty)
      });
    }else if (field === "operation_type" ){
      setstockRowData({
        ...stockRowData,
        [field]:data,
        sumQty: data ==="giris" ? stockRowData.qty : 0-stockRowData.qty,
        sumPrice: data ==="giris" ? stockRowData.price*stockRowData.qty : 0-(stockRowData.price*stockRowData.qty)
      });
    }else{
      setstockRowData({
        ...stockRowData,
        [field]:data
      });
    }

  }
  //******************************************************************************************************************************************************************/
  const removeStockRow = (stockRow) => {
    if(productID && stockRow._id){
      dispatch(deleteProductStock(productID,stockRow._id))
      .then(res=>{
        setTimeout(() => {
          dispatch((getProductDetails({id:productID})))
          .then(res=>{
            message.success(messages["alert.savesuccess"],  2 );
          },500);
        });
      })
    }
   
  }
  //******************************************************************************************************************************************************************/
  const onSaveStockRow = () => {
     if(stockRowData._id){
      dispatch(updateProductStockOperation(stockRowData))
      .then(res=>{
        setTimeout(() => {
          dispatch((getProductDetails({id:productID})))
          .then(res=>{
            message.success(messages["alert.savesuccess"],  2 );
          });
        },1000);
        setState({
          ...state,
          stockRowModalActive: null
        });
      })
    }else{
      dispatch(createProductStock(stockRowData))
      .then(res=>{
        setTimeout(() => {
          dispatch((getProductDetails({id:productID})))
          .then(res=>{
            message.success(messages["alert.savesuccess"],  2 );
          });
        },1000);
        setState({
          ...state,
          stockRowModalActive: null
        });
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const addNewStockRow = ( ) => {
    setstockRowData({
      product_id: productID,
      operation_date: moment(new Date()),
      operation_center:"",
      operation_type: "",
      productname: dataSource.urun_adi[0],
      order_id:null,
      orderitem_id:null,
      company_id:null,
      qty: 1,
      unit: "Adet",
      description: "Teslimat Stok Hareketi",
      price : 1,
      doviz : "$",
      component_type:"product",
      status:0
    });
    setState({
      ...state,
      stockRowModalActive: true
    });
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const addNewProductPhotometryRow = () => {
    let temp = productPhotometry;
    if (!temp){
      temp=[]
    }
    temp.push({
      no:5,
      image:"",
      description:["",""]
    })
    setState({
      ...state,
      productPhotometryModalActive: true,
      productPhotometryModalIndex:(temp.length)-1
    });
    setDataSource({
      ...dataSource,
      productPhotometry:temp
    });

  };
  //******************************************************************************************************************************************************************/
  const removeProductPhotometryRow = (index) => {
    let temp = productPhotometry;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        productPhotometry:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateProductPhotometryRow = (Field,eData,i) => {
    let tempdata = productPhotometry;
    if(Field==="no"){
      tempdata[state.productPhotometryModalIndex][Field] = Number(eData);
    }else{
      tempdata[state.productPhotometryModalIndex][Field][i] = eData;
    }
    setDataSource({
      ...dataSource,
      productPhotometry:tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const addNewOtherPhotoRow = () => {
    let temp = otherPhotos;
    if (!temp){
      temp=[]
    }
    temp.push({
      no:5,
      image:"",
      description:["",""]
    })
    setState({
      ...state,
      otherPhotoModalActive: true,
      otherPhotoModalIndex:(temp.length)-1
    });
    setDataSource({
      ...dataSource,
      otherPhotos:temp
    });

  };
  //******************************************************************************************************************************************************************/
  const removeOtherPhotoRow = (index) => {
    let temp = otherPhotos;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        otherPhotos:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateOtherPhotoRow = (Field,eData,i) => {
    let tempdata = otherPhotos;
    if(Field==="no"){
      tempdata[state.otherPhotoModalIndex][Field] = Number(eData);
    }else{
      tempdata[state.otherPhotoModalIndex][Field][i] = eData;
    }
    setDataSource({
      ...dataSource,
      otherPhotos:tempdata
    });
  }
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const addNewProductionStepRow = () => {
    let temp = productionSteps;
    if (!temp){
      temp=[]
    }
    temp.push({
      no:5,
      operation:"",
      operationcomponent:[],
      operationdescription:""
    })
    setState({
      ...state,
      productionStepModalActive: true,
      productionStepModalIndex:(temp.length)-1
    });
    setDataSource({
      ...dataSource,
      productionSteps:temp
    });

  };
  //******************************************************************************************************************************************************************/
  const removeproductionStepRow = (index) => {
    let temp = productionSteps;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        productionSteps:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateProductionStep = (Field,eData,i) => {
    let tempdata = productionSteps;
    tempdata[state.productionStepModalIndex][Field] = eData;
    setDataSource({
      ...dataSource,
      productionSteps:tempdata
    });
  }
    //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  //******************************************************************************************************************************************************************/
  const addNewProductDownloadRow = () => {
    let temp = productDownloads;
    if (!temp){
      temp=[]
    }
    temp.push({
     
      base64:"",
      file_descirpiption:["",""]
    })
    setState({
      ...state,
      productDownloadModalActive: true,
      productDownloadModalIndex:(temp.length)-1
    });
    setDataSource({
      ...dataSource,
      productDownloads:temp
    });

  };
  //******************************************************************************************************************************************************************/
  const removeProductDownloadRow = (index) => {
    let temp = productDownloads;
    if (temp.length>0){ 
      temp.splice(index, 1);
      setDataSource({
        ...dataSource,
        productDownloads:temp
      });
    }
  }
  //******************************************************************************************************************************************************************/
  const updateProductDownloadRow = (Field,eData,i) => {
    let tempdata = productDownloads;
    if(Field==="type"){
      tempdata[state.productDownloadModalIndex][Field] = eData;
    }else{
      tempdata[state.productDownloadModalIndex][Field][i] = eData;
    }
    setDataSource({
      ...dataSource,
      productDownloads:tempdata
    });
  }
  //******************************************************************************************************************************************************************/
//console.log(dataSource)

  return (
    <>
      <PageHeader
        ghost
        title={dataSource && dataSource.urun_adi ? dataSource.urun_adi[lang]: "..."} 
        buttons={buttonList}
      />
      <Main>
        <MenuListWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={6} xl={7} lg={8} xs={24}>
              <DetailMenu 
                MenuList={MenuList} 
                data0={messages["model.kategori"+ dataSource.model_category]}
                data1={messages["genel.stocksum"]+" :"+(productDetail.productStock ? productDetail.productStock : "Hesaplanıyor.."  )}
                messages={messages} 
                image={mainImage} 
                imageField={"img"}
                imageWidth={100}
                tabID={tabID}
                beforeUpload={null}
                collapsed={collapsed}
                toggleCollapsed={toggleCollapsed}
                gotoPage={gotoPage}
                updateData={null}
              />
            </Col>
            <Col xxl={18} xl={17} lg={16} xs={24}>
              {state.tabID === "profil" ? 
                <ProductGenerals
                  messages={messages}
                  lang={lang}
                  productDetailLoading={productDetailLoading}
                  certificates={certificates}
                  product={dataSource}
                  CurrencyList={CurrencyList}
                  modelListOptions={modelListOptions}
                  updateData={updateData}
                  USDSatis={USDSatis}
                  EURSatis={EURSatis}
                  onDeleteProduct={onDeleteProduct}
                  onChangeProductModel={onChangeProductModel}
                />
              :state.tabID === "electrical" ? 
                <ElectricalInfo
                  messages={messages}
                  lang={lang}
                  electricalInfo={electricalInfo}
                  productDetailLoading={productDetailLoading}
                  addNewElectircalInfoRow={addNewElectircalInfoRow}
                  removeElectricalInfoRow={removeElectricalInfoRow}
                  dataCopy={dataCopy}
                  dataPaste={dataPaste}
                  toggleModal={toggleModal}
                />
              :state.tabID === "optical" ? 
                <OpticalInfo
                  messages={messages}
                  lang={lang}
                  opticalInfo={opticalInfo}
                  productDetailLoading={productDetailLoading}
                  addNewOpticalInfoRow={addNewOpticalInfoRow}
                  removeOpticalInfoRow={removeOpticalInfoRow}
                  dataCopy={dataCopy}
                  dataPaste={dataPaste}
                  toggleModal={toggleModal}
                />
              :state.tabID === "mechanical" ? 
                <MechanicalInfo
                  messages={messages}
                  lang={lang}
                  mechanicalInfo={mechanicalInfo}
                  productDetailLoading={productDetailLoading}
                  addNewMechanicalInfoRow={addNewMechanicalInfoRow}
                  removeMechanicalInfoRow={removeMechanicalInfoRow}
                  dataCopy={dataCopy}
                  dataPaste={dataPaste}
                  toggleModal={toggleModal}
                />
              :state.tabID === "other" ? 
                <OtherInfo
                  messages={messages}
                  lang={lang}
                  otherInfo={otherInfo}
                  productDetailLoading={productDetailLoading}
                  addNewOtherInfoRow={addNewOtherInfoRow}
                  removeOtherInfoRow={removeOtherInfoRow}
                  dataCopy={dataCopy}
                  dataPaste={dataPaste}
                  toggleModal={toggleModal}
                />
                :state.tabID === "drawings" ? 
                <TechnicalDrawingsList
                  messages={messages}
                  lang={lang}
                  technicalDrawings={technicalDrawings}
                  productDetailLoading={productDetailLoading}
                  addTechnicalDrawingRow={addTechnicalDrawingRow}
                  removeTechnicalDrawingRow={removeTechnicalDrawingRow}
                  dataCopy={dataCopy}
                  dataPaste={dataPaste}
                  toggleModal={toggleModal}
                />
              :state.tabID === "operations" ?
                <ProductOperations
                  messages={messages}
                  lang={lang}
                  productDetailLoading={productDetailLoading}
                  productListLoading={productListLoading}
                  production={production}
                  productList={productList}
                  summary={summary ? Number(summary) : 0}
                  toggleModal={toggleModal}
                  calculateProductionList={calculateProductionList}
                  removeProRow={removeProRow}
                  addNewProRow={addNewProRow}
                  dataCopy={dataCopy}
                  dataPaste={dataPaste}
                />
              :state.tabID === "images" ?
                <ProductImages
                  messages={messages}
                  lang={lang}
                  productDetailLoading={productDetailLoading}
                  productImageList={productImages}
                  removeProductImageRow={removeProductImageRow}
                  addNewProductImageRow={addNewProductImageRow}
                  dataCopy={dataCopy}
                  dataPaste={dataPaste}
                  toggleModal={toggleModal}
                />
              :state.tabID === "photometry" ?
                <ProductPhotometryList
                  messages={messages}
                  lang={lang}
                  productDetailLoading={productDetailLoading}
                  productPhotometry={productPhotometry}
                  removeProductPhotometryRow={removeProductPhotometryRow}
                  addNewProductPhotometryRow={addNewProductPhotometryRow}
                  dataCopy={dataCopy}
                  dataPaste={dataPaste}
                  toggleModal={toggleModal}
                />
              :state.tabID === "productionsteps" ?
                <ProductionSteps
                  messages={messages}
                  lang={lang}
                  productDetailLoading={productDetailLoading}
                  productionSteps={productionSteps}
                  addNewProductionStepRow={addNewProductionStepRow}
                  removeproductionStepRow={removeproductionStepRow}
                  dataCopy={dataCopy}
                  dataPaste={dataPaste}
                  toggleModal={toggleModal}
                />
              :state.tabID === "otherphotos" ?
                <OtherPhotoList
                  messages={messages}
                  lang={lang}
                  productDetailLoading={productDetailLoading}
                  otherPhotos={otherPhotos}
                  removeOtherPhotoRow={removeOtherPhotoRow}
                  addNewOtherPhotoRow={addNewOtherPhotoRow}
                  dataCopy={dataCopy}
                  dataPaste={dataPaste}
                  toggleModal={toggleModal}
                />
                :state.tabID === "productDownloads" ?
                <ProductDownloads
                  messages={messages}
                  lang={lang}
                  productDetailLoading={productDetailLoading}
                  productDownloads={productDownloads}
                  addNewProductDownloadRow={addNewProductDownloadRow}
                  removeProductDownloadRow={removeProductDownloadRow}
                  dataCopy={dataCopy}
                  dataPaste={dataPaste}
                  toggleModal={toggleModal}
                />
              :state.tabID ==="stock" ?
                <StockOperations
                  messages={messages}
                  lang={lang}
                  stockList={StockOpList}
                  loading={productDetailLoading}
                  removeStockRow={removeStockRow}
                  toggleModal={toggleModal}
                  addNewStockRow={addNewStockRow}
                />
              :null}
            </Col>
          </Row>
        </MenuListWrapper>
      </Main>
        {state.proModalActive && 
        <ProModal 
          messages={messages}
          CurrencyList={CurrencyList}
          product={dataSource}
          state={state}
          updateProRow={updateProRow}
          toggleModal={toggleModal}
        />}
        {state.productImageModalActive && 
        <ImageModal 
          messages={messages}
          lang={lang}
          beforeUpload={beforeUpload}
          toggleModal={toggleModal}
          productImageModalActive={state.productImageModalActive}
          productImage={productImages[state.productImageModalIndex]}
          updateProductImageRow={updateProductImageRow}
        />}
        {state.stockRowModalActive && 
        <StockRowModal
          messages={messages}
          toggleModal={toggleModal}
          onSaveStockRow={onSaveStockRow}
          stockRowModalActive={state.stockRowModalActive}
          onChangeStockRowData={onChangeStockRowData}
          delivery={stockRowData}

        />}
        <CompanyListModal
          companyList={companies}
          messages={messages}
          lang={lang}
          loading={companyListLoading}
          companyListModalActive={state.companyListModalActive}
          searchCompany={searchCompany}
          selectCompany={selectCompany}
          toggleModal={toggleModal}
        />
        {state.electricalInfoModalActive && 
          <ElectricalInfoModal 
            messages={messages}
            lang={lang}
            electricalInfoModalActive={state.electricalInfoModalActive}
            electricalInfo={electricalInfo[state.electricalInfoModalIndex]}
            visibilityList={visibilityList}
            updateElectricalInfoRow={updateElectricalInfoRow}
            toggleModal={toggleModal}
          />}
        {state.opticalInfoModalActive && 
          <OpticalInfoModal 
            messages={messages}
            lang={lang}
            opticalInfoModalActive={state.opticalInfoModalActive}
            opticalInfo={opticalInfo[state.opticalInfoModalIndex]}
            visibilityList={visibilityList}
            updateOpticalInfoRow={updateOpticalInfoRow}
            toggleModal={toggleModal}
          />}
        {state.mechanicalInfoModalActive && 
          <MechanicalInfoModal 
            messages={messages}
            lang={lang}
            mechanicalInfoModalActive={state.mechanicalInfoModalActive}
            mechanicalInfo={mechanicalInfo[state.mechanicalInfoModalIndex]}
            visibilityList={visibilityList}
            updateMechanicalInfoRow={updateMechanicalInfoRow}
            toggleModal={toggleModal}
          />}
        {state.otherInfoModalActive && 
          <OtherInfoModal 
            messages={messages}
            lang={lang}
            otherInfoModalActive={state.otherInfoModalActive}
            otherInfo={otherInfo[state.otherInfoModalIndex]}
            visibilityList={visibilityList}
            updateOtherInfoRow={updateOtherInfoRow}
            toggleModal={toggleModal}
          />}
        {state.technicalDrawingModalActive && 
          <TechnicalDrawingModal 
            messages={messages}
            lang={lang}
            beforeUpload={beforeUpload}
            technicalDrawingModalActive={state.technicalDrawingModalActive}
            technicalDrawing={technicalDrawings[state.technicalDrawingModalIndex]}
            updateTechnicalDrawingRow={updateTechnicalDrawingRow}
            toggleModal={toggleModal}
          />}
        {state.productPhotometryModalActive && 
          <ProductPhotometryModal 
            messages={messages}
            lang={lang}
            beforeUpload={beforeUpload}
            productPhotometryModalActive={state.productPhotometryModalActive}
            productPhotometry={productPhotometry[state.productPhotometryModalIndex]}
            updateProductPhotometryRow={updateProductPhotometryRow}
            toggleModal={toggleModal}
          />}
        {state.otherPhotoModalActive && 
          <OtherPhotoModal 
            messages={messages}
            lang={lang}
            beforeUpload={beforeUpload}
            otherPhotoModalActive={state.otherPhotoModalActive}
            otherPhoto={otherPhotos[state.otherPhotoModalIndex]}
            updateOtherPhotoRow={updateOtherPhotoRow}
            toggleModal={toggleModal}
          />}
          {state.productionStepModalActive && 
          <ProductionStepModal 
            messages={messages}
            lang={lang}
            productionStepModalActive={state.productionStepModalActive}
            productionSteps={productionSteps[state.productionStepModalIndex]}
            updateProductionStep={updateProductionStep}

            toggleModal={toggleModal}
          />}
          {state.productDownloadModalActive && <ProductDownloadsModal 
            messages={messages}
            lang={lang}
            beforeUpload={beforeUpload}
            productDownloadModalActive={state.productDownloadModalActive}
            productDownload={productDownloads[state.productDownloadModalIndex]}
            updateProductDownloadRow={updateProductDownloadRow}
            toggleModal={toggleModal}
          />}
    </>
  );
}



export  {ProductDetails};
