import React from 'react';
import { Table,Popconfirm,Tag ,Col,Row} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import blank from '../../../static/img/resyok.jpeg';
import {getProductCode} from '../../../helpers/getProductCode';
import {currencyFormatter} from '../../../helpers/currencyFormat';
import { faCirclePlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const OfferRows = ({offer,loading,messages,EURSatis,USDSatis,toggleModal,addNewOfferRow,removeOfferRow}) => {
console.log(offer)
  const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbol: ""
  }
  const productTableData = [];
  if (offer !== null && offer.offeritem) {
    offer.offeritem.map((data,index) => {
      const { _id,urun_resim, urun_miktari, urun_fiyati, urun_birimi, urun_kodu, urun_adi,urun_id,urun_dovizi,toplamDoviz,toplamTL,toplamKdvTL,toplamKdvDoviz ,order_type,material_id,siparis_durumu} = data;
      const rowImg = urun_resim ? urun_resim : blank;
      const productCode = getProductCode("",urun_kodu[0],urun_kodu[1],urun_kodu[2],urun_kodu[3],urun_kodu[4],urun_kodu[5]);
      let color =""
      if (siparis_durumu === 0 ){color = 'magenta'} 
      else if (siparis_durumu === 1){color = 'red'} 
      else if (siparis_durumu === 2){color = 'default'} 
      else if (siparis_durumu === 3){color = 'default'} 
      else if (siparis_durumu === 4){color = "blue"} 
      else if (siparis_durumu === 5){color = 'green'} 
      return productTableData.push({
        key: index,
        product: (
          <Row align="middle">
            <Col md={6} xs={24}>
              <Col span={24} align="center"><img style={{ width: 100 }} src={rowImg} alt="" /></Col>
              <Col span={24} align="center">{offer.teklif_durumu>0 && <Tag color={color} style={{marginLeft:"10px"}}>{messages["order.orderStatus"+siparis_durumu]}</Tag>}</Col>                         
            </Col>
            <Col md={18} xs={24}>
              {order_type ==='product' ? 
                <Col span={24}>
                  <Heading as="h6">{urun_adi.substring(0,50)}</Heading>
                  <span className="info-title">{messages['product.code']}</span><span>{productCode}</span> 
                </Col>
                :order_type ==='material' ?
                <Col span={24}>
                  <Heading as="h6">{urun_adi.substring(0,50)}</Heading>
                  <span className="info-title">Malzeme {!material_id ? "Yeniden Seçiniz":"Seçilmiş"}</span>
                </Col>
                : order_type ==='default' ? 
                <Col span={24}>
                  <Heading as="h6">{urun_adi.substring(0,50)}</Heading>
                  <span className="info-title">Takip Edilecek Ürün</span>
                </Col>
                :
                <Col span={24}>
                  <Heading as="h6">{urun_adi.substring(0,50)}</Heading>
                  <span className="info-title">Alt Montaj</span>
                </Col>}
            </Col>
          </Row>
        ),
        price: 
        <div>
          <span className="cart-single-price">{currencyFormatter(urun_fiyati,defaultOptions) +" "+urun_dovizi}</span>
          <p className="cart-single-price">{(urun_dovizi==="$" ? currencyFormatter(USDSatis*urun_fiyati,defaultOptions)  : urun_dovizi==="€" ? currencyFormatter(EURSatis*urun_fiyati,defaultOptions) : currencyFormatter(urun_fiyati,defaultOptions))} ₺</p>
        </div>,
        
        quantity: (
          <div className="cart-single-quantity">
            <span>{currencyFormatter(urun_miktari)}</span>
            <p>{urun_birimi}</p>
          </div>
        ),
        total:
        <div>
            <span className="cart-single-t-price">{currencyFormatter(toplamDoviz)  +" "+urun_dovizi}</span>
            <p className="cart-single-t-price">{currencyFormatter(toplamTL)  +" ₺"}</p>
        </div>,
         tax:
         <div>
             <span className="cart-single-t-price">{currencyFormatter(toplamKdvDoviz)  +" "+urun_dovizi}</span>
             <p className="cart-single-t-price">{currencyFormatter(toplamKdvTL)  +" ₺"}</p>
         </div>,       
        
        action: (
       
          <>
            <Button
              onClick={() => toggleModal("offerRowModalActive",true,index)}
      
              type="info"
              shape="circle"
              transparented
            >
              <FontAwesomeIcon icon={faEdit} size='1x'/>
            </Button>
            <Popconfirm
              title={messages["alert.deletequest"]}
              okText={messages["genel.yes"]}
              cancelText={messages["genel.no"]}
              onConfirm={()=>removeOfferRow(index)}
            >
              <Button
                type="danger"
                shape="circle"
                transparented
              >
                <FontAwesomeIcon icon={faTrash} size='1x'/>
              </Button>
            </Popconfirm>
          </>
          
        ),
      });
    });
  }

  const productTableColumns = [
    {
      title: messages["product.name"],
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: messages["genel.qty"],
      dataIndex: 'quantity',
      key: 'quantity',
      align:'center'
    },
    {
      title: messages["genel.price"],
      dataIndex: 'price',
      key: 'price',
      align:'right'
    },
    {
      title:  messages["genel.totalprice"],
      dataIndex: 'total',
      key: 'total',
      align:'right'
    },
    {
      title: messages["genel.sumkdv"],
      dataIndex: 'tax',
      key: 'total',
      align:'right'
    },
    {
      title: 
        <Button
          onClick={() => addNewOfferRow()}
          type="success"
          shape="circle"
          transparented
        >
          <FontAwesomeIcon icon={faCirclePlus} size='1x'/>
        </Button>
      ,
      dataIndex: 'action',
      key: 'action',
      align:'right'
    },
  ];

  return (
    <>

      <div className="table-cart table-responsive" style={{marginBottom:20}}>
          <Table pagination={false} loading={loading} dataSource={productTableData} columns={productTableColumns} />
      </div>

    </>
  );
}

export {OfferRows};
