import React from 'react';
import { Table ,Button,Popconfirm, Spin,Tag} from 'antd';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit,faTrash,faCirclePlus,faCopy,faPaste} from '@fortawesome/free-solid-svg-icons';
import { Cards } from '../../../components/cards/frame/cards-frame';



const ProductDownloads = ({productDownloads,messages,lang,productDetailLoading,toggleModal,dataCopy,dataPaste,addNewProductDownloadRow,removeProductDownloadRow}) => {
  let info = productDownloads && productDownloads.length && productDownloads.map((el, idx) => ({key: idx, ...el}))
  const moreContent = (
    <>
      <NavLink to="#" onClick={()=>addNewProductDownloadRow()}>
        <span><FontAwesomeIcon icon={faCirclePlus} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.add"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>dataCopy()}>
        <span><FontAwesomeIcon icon={faCopy} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.copy"]}</span>
      </NavLink>
      <NavLink to="#" onClick={()=>dataPaste()}>
        <span><FontAwesomeIcon icon={faPaste} style={{marginRight:"5px",color:'#9299B8'}} size='sm' />{messages["button.paste"]}</span>
      </NavLink>
  
    </>
  );
  
  const productDownloadsColumn = [
    {
      title: [messages["genel.sira"]],
      dataIndex: 'key',
      key: 'key',
      sorter: (a, b) => b.no - a.no,
      sortOrder:'descent',
      align: 'left',
      render: (text) => Number(text)+1,
      width: '15%',
    },
    {
      title: [messages["product.images"]],
      dataIndex: 'base64',
      key: 'base64',
      align:'center',
      render: (text,record) =>    
        record.type ==="ltd" ? 
     
        <div style={{display:'flex',flexDirection:'column'}}>
          <iframe src={text}  height={200} style={{marginBottom:'5px'}}/>
          <span  style={{marginBottom:'5px'}}>{record.name+" / "+record.size}</span>
          <Tag className='blue' style={{marginBottom:'5px'}}>{record.file_descirpiption[0]}</Tag>
          <Tag className='yellow'>{record.file_descirpiption[1]}</Tag>
        </div>


        :record.type ==="application/pdf" ? 
        <div style={{display:'flex',flexDirection:'column'}}>
          <iframe src={text} frameBorder="0" height={400} style={{marginBottom:'5px'}}/>
          <span  style={{marginBottom:'5px'}}>{record.name+" / "+record.size}</span>
          <Tag className='blue' style={{marginBottom:'5px'}}>{record.file_descirpiption[0]}</Tag>
          <Tag className='yellow'>{record.file_descirpiption[1]}</Tag>
        </div>
        :
        null,
        
      width: '70%',
    },
    {
      title: "",
      dataIndex: 'action',
      key: 'action',
      width: '15%',
      render: (text,record) => 
      <>
        <Button
          shape="circle"
          size="small"
          style={{marginRight:"5px"}}
          onClick={()=>toggleModal("productDownloadModalIndex",record.key)}
        >
          <FontAwesomeIcon icon={faEdit} style={{color:'#9299B8'}} size='sm'/>
        </Button > 
        <Button
          shape="circle"
          size="small"
          onClick={()=>removeProductDownloadRow(record.key)}
        >
          <FontAwesomeIcon icon={faTrash} style={{color:' #e85967'}} size='sm'/>
        </Button>  
      </>
    }
  ];

  return (
    <div className="full-width-table" >
      <Cards
        title={messages["product.otherphoto"]}
        size="large"
        bodypadding="0px"
        more={moreContent}
      >
        <div className="table-bordered top-seller-table table-responsive" style={{minHeight:'730px'}}>
          <Table 
            columns={productDownloadsColumn} 
            dataSource={info} 
            pagination={false} 
            loading={productDetailLoading}
            scroll={{ y: 660 }}
          />
        </div>
      </Cards>
    </div>
  );
}
 


export  {ProductDownloads};
